import React from 'react';
import { NavigationBar } from './navigationBar';
import { Footer } from './footer'
import { Modals } from './modal';
import { ScriptCDN } from './scriptCDN';
import { resources } from './locales.js'

export function PolicyContent({ lang }) {

    const disclaimer = []
    if (lang !== resources.defaultTerms) {
        disclaimer.push(
            <>
                <br/><br/>
                <h4>{resources[lang]["cookie"]["disclaimer"]}</h4>
            </>
        )
    }   

    return (
        <div>
            <NavigationBar lang={lang}></NavigationBar>

            <div class="container" >
                <div class="row ">
                    <div class="col-12 col-xs-12 col-sm-6 col-md-8 mx-auto mt-5 text-center text-zer-urdin">
                        <h1>{resources[lang]["cookie"]["title"]}</h1>
                        {disclaimer}
                    </div>
                </div>
                <div class="row  justify-content-left">
                    <div class="col-12 col-xs-12 col-sm-6 col-md-8 mx-auto mt-5 pt-5 ">
                        <h2>¿Qué son las cookies?</h2>
                        <br/>
                        <p>Una cookie es un pequeño paquete de datos que un navegador genera e intercambia con un servidor web al acceder a una página web. Las cookies permiten a la página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario. </p>
                        <br/><br/>
                        <h2>¿Qué tipos de cookies utiliza esta página web?</h2>
                        <br/>
                        <p>Esta página web utiliza los siguientes tipos de cookies:</p>
                        <ul>
                            <li>Cookies de análisis: Son aquéllas que nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen nuestros usuarios del servicio. Para ello se analiza su navegación en nuestra página web con el fin de mejorar el servicio que ofrecemos.</li>
                            <li>Cookies técnicas: Son aquellas que permiten al usuario la navegación a través del área restringida y la utilización de sus diferentes funciones. Son necesarias para la navegación y el buen funcionamiento de nuestra página web.</li>
                            <li>Cookies de personalización: Son aquellas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma o el tipo de navegador a través del cual se conecta.</li>
                        </ul>
                        <p>Todas las cookies están totalmente anonimizadas y nunca guardan información que permita en ningún caso identificar al usuario.</p>

                        <br/><br/>
                        <h2>Tipos de cookies según la entidad que las gestiona:</h2>
                        <br/>
                        <ul>
                            <li><p>Cookies de origen o propias: Son aquellas que se generan en tu equipo y se pueden intercambiar con nuestro servidor web.</p></li>
                            <li><p>Cookies de terceros: Son aquellas que se generan en tu equipo y que son gestionadaes por otras entidades colaboradoras. En concreto usamos Google Analytics como sistema de analítica web.</p></li>

                        </ul>
                        <br/><br/>
                        <h2>Tipos de cookies según el plazo de tiempo que permanecen activadas:</h2>
                        <br/>
                        <ul>
                            <li>Cookies de sesión: Son cookies temporales que permanecen en el archivo de cookies de tu navegador hasta que abandonas la página web, por lo que ninguna queda registrada en tu dispositivo. La información obtenida por medio de estas cookies, sirve para analizar pautas de tráfico en la web. Esto nos permite mejorar tanto la experiencia y facilitar el uso del servicio.</li>
                            <li>Cookies persistentes: Son almacenadas en tu dispositivo y son leídas en cada nueva visita. Tienen una fecha de expiración determinada y dejará de ser válida después de esa fecha.</li>
                        </ul>

                        <br/><br/>
                        <h2>Desactivar las cookies.</h2>
                        <br/>
                        <p>Se pueden permitir, bloquear o eliminar las cookies instaladas en un dispositivo mediante la configuración del navegador utilizado.</p>
                        <p>A continuación, puedes encontrar enlaces con información sobre gestionar, eliminar o desactivar las cookies en los principales navegadores:</p>
                        <ul>
                            <li>Configurar cookies en Google Chrome:	<a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="nofollow">https://support.google.com/chrome/answer/95647?hl=es</a></li>
                            <li>Configurar cookies en Microsoft Internet Explorer y Edge: <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="nofollow">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
                            <li>Configurar cookies en Mozilla Firefox: <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" rel="nofollow">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we"</a></li>
                            <li>Configurar cookies en Safari para MacOS (Apple): <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank" rel="nofollow">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></li>
                            <li>Configurar cookies en Safari iPhone, iPad o iPod touch (Apple): <a href="https://support.apple.com/es-es/HT201265" target="_blank" rel="nofollow">https://support.apple.com/es-es/HT201265</a></li>
                            <li>Configurar cookies en dispositivos Android (Google): <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DAndroid&hl=es-419" target="_blank" rel="nofollow">https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DAndroid&hl=es-419</a></li>
                        </ul>

                        <br/><br/>
                        <h2>Advertencia sobre eliminar cookies.</h2>
                        <br/>
                        <p>Usted puede eliminar y bloquear las cookies de este sitio, pero parte del sitio podría dejar de funcionar correctamente y la calidad del servicio podría verse afectada.</p>
                        <p>Si tiene cualquier duda acerca de nuestra política de cookies, puede contactar con nosotros a través de la sección de Contacto.</p>

                        <p>Como usuario aceptas expresamente, por la utilización de este site, el tratamiento de la información recabada; asimismo reconoces conocer la posibilidad de rechazar el tratamiento de tales datos o información rechazando el uso de Cookies mediante la configuración correspondiente en cada navegador.</p>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <Modals lang={lang}></Modals>
            <Footer lang={lang}></Footer>
            <ScriptCDN lang={lang} page="policy"></ScriptCDN>
        </div>
    );
}
